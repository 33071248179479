$primary: #170ce8;


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3c14aa");
  
@font-face {
  font-family: 'HelveticaNeueLTPro-Md';
  src: url('../font.woff2') format('woff2'), url('../font.woff') format('woff');
}

body {
    margin: 0;
    padding: 0;
    color: $primary;
    font-family: 'HelveticaNeueLTPro-Md', serif;
}
main {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    clip-path: url('#mask');
    background: $primary;

    canvas {
        opacity: 1;
    }
    
}

.navigation {
    position: relative;
    z-index: 10;
    .navItem[href="#expertise"] {
        position: fixed;
        top: 28px;
        left: 28px;
        font-size: 30px;
        color: $primary;
        text-decoration: none;
        @media screen and (max-width: 768px) {
            top: 20px;
            left: 20px;
            font-size: 22px;
        }
    }
    .navItem[href*=mailto] {
        position: fixed;
        top: 28px;
        right: 28px;
        font-size: 30px;
        color: $primary;
        text-decoration: none;
        @media screen and (max-width: 768px) {
            top: 20px;
            right: 20px;
            font-size: 22px;
        }
    }
    .navItem[href="#imprint"] {
        position: fixed;
        bottom: 28px;
        right: 28px;
        font-size: 18px;
        color: $primary;
        text-decoration: none;
        @media screen and (max-width: 768px) {
            bottom: 20px;
            right: 20px;
        }
    }
    .navItem {
        &:after {
            content: '';
            height: 3px;
            width: 0;
            display: block;
            position: absolute;
            top: 100%;
            background: $primary;
            transition: width 500ms ease;
        }
        &:hover,
        &.active {
            &:after {
                width: 100%;
            }
        }
    }
}
.centralize {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    p {
        margin: 0;
        font-size: 27px;
        margin: 1em auto 0;
        padding: 0 20px;
        text-align: center;
        @media screen and (max-width: 768px) {
            font-size: 22px;
        }
    }
}

.logo {
    max-width: 828px;
    width: calc(100% - 40px);
    opacity: 0;
    svg {
        * {
            fill: $primary;
        }
    }
}
#logo-mask {
    width: 100%;
    position: absolute;
    top: 0; 
    left: 0;
}

#expertise {
    position: absolute;
    top: 90px;
    left: 28px;
    @media screen and (max-width: 768px) {
        left: 20px;
        right: 20px;
    }
    ul {
        list-style: none;
        margin: 0 0 1.5em;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        li {
            font-size: 36px;
            display: inline-block;
            background: white;
            padding: 2px 5px 2px 0;
            transform: translate(-50%);
            transition: transform 500ms ease, opacity 500ms ease;
            opacity: 0;
            z-index: 100;
            position:relative;
            @media screen and (max-width: 768px) {
                font-size: 26px;
                background: $primary;
                color: white;
                
            }
        }
    }
    &.open {
        ul {
            li {
                opacity: 1;
                transform: translate(0);
            }
        }
    }
}

#imprint {
    position: absolute;
    top: 90px;
    left: 28px;
    right: 28px;
    bottom: 0;
    overflow-y: auto;
    background: white;
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms ease;
    z-index: 101;
    @media screen and (max-width: 768px) {
        right: 20px;
        left: 20px;
    }
    &.open {
        opacity: 1;
        pointer-events: initial;
    }
    .imprint__content {
        max-width: 1000px;
        width: calc(100% - 40px);
        .close {
            color: $primary;
            text-decoration: none;
            position: relative;
            display: inline-block;
            &:after {
                content: '';
                width: 100%;
                position: absolute;
                bottom: 2px;
                left: 0;
                height: 1px;
                background: $primary;

            }
        }
        h2 {
            font-size: 30px;
            font-weight: normal;
            margin: 0.5em 0 1em;
            @media screen and (max-width: 768px) {
                font-size: 22px;
            }
        }
        h3 {
            font-size: 16px;
            font-weight: normal;
            margin: 2em 0 1em;
        }
        p {
            font-size: 16px;
            margin: 0 0 1em;
            line-height: 20px;
        }
    }
    &.open {
       
    }
}